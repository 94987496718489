import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Content from "../components/content"
import NewsPreview from "../components/utility/news-preview"
import Prose from "../components/utility/prose";
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from '../components/utility/customForm'

const IndexPage = ({data}) => {
  const page = data.strapiHomepage
  const content = page.content
  return (
  <>
    <Seo title={page.title} />
    {/* <Prose>
      <h1 className="mb-14">{page.title}</h1>
    </Prose> */}
    <Content content={content}></Content>
    <NewsPreview />
    <Prose>
    <div className="text-center">
      <MailchimpSubscribe
      url="https://touchstonedistrictservices.us19.list-manage.com/subscribe/post?u=9e9660a8cc948aa82bf3acc40&amp;id=fa43bc00d0&amp;f_id=004289e4f0"
      render={({ subscribe, status, message }) => (
          <CustomForm
          status={status}
          message={message}
          onValidated={formData => subscribe(formData)}
          />
      )}
      />
    </div>
    </Prose>
  </>
)}

export default IndexPage


export const indexPageQuery = graphql`
  query indexPage {
    strapiHomepage {
      id
      title
      content {
        ... on STRAPI__COMPONENT_PAGE_ALERT {
          ...pageAlert
        }
        ... on STRAPI__COMPONENT_PAGE_CARDS {
          ...pageCards
        }
        ... on STRAPI__COMPONENT_PAGE_GALLERY {
          ...pageGallery
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE {
          ...pageImage
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE_TEXT {
          ...pageImageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT {
          ...pageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_IMAGE {
          ...pageTextImage
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_TEXT {
          ...pageTextText
        }
        ... on STRAPI__COMPONENT_PAGE_WIDGET {
          ...pageWidget
        }
      }
    }
  }
`
